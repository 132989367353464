import React from 'react';
import { Link as GLink } from 'gatsby';
import { Typography, Container, Grid } from '@material-ui/core';
import BackgroundCard from '../../../../components/BackgroundCard';
import { Section, SectionHeader } from './style';
import health from '../../../../assets/img/health.png';
import taste from '../../../../assets/img/taste.png';

const Categories = ({ strings, lang }) => {
    return (
        <Section>
            <Container>
                <SectionHeader>
                    <Typography variant="h2">{strings.title}</Typography>
                    <Typography>{strings.subtitle}</Typography>
                </SectionHeader>
                <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                        <GLink to={`/${lang}/products/taste`}>
                            <BackgroundCard title={strings.taste} src={taste} />
                        </GLink>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <GLink to={`/${lang}/products/health`}>
                            <BackgroundCard title={strings.health} src={health} />
                        </GLink>
                    </Grid>
                </Grid>
            </Container>
        </Section>
    );
};

export default Categories;
