import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Card = styled(Paper)<{ src: string }>`
    display: flex;
    height: 384px;
    background: url(${({ src }) => src}) no-repeat center;
    background-size: cover;
    box-shadow: 0 8px 80px -24px #67271d;
`;

export const CardContent = styled.div`
    text-align: left;
    padding: 32px;
    margin-top: auto;

    h3 {
        color: ${({ theme }) => theme.palette.secondary.contrastText};
    }
`;
