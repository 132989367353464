import React from 'react';
import Categories from './components/Categories';
import strings from './strings';

const Products = ({ lang }) => {
    return (
        <>
            <Categories strings={strings[lang]} lang={lang} />
        </>
    );
};

export default Products;
