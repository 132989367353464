import React from 'react';
import { Typography } from '@material-ui/core';
import { Card, CardContent } from './style';

const BackgroundCard = ({ title, src }: { title: string; src: string }) => {
    return (
        <Card src={src}>
            <CardContent>
                <Typography variant="h3">{title}</Typography>
            </CardContent>
        </Card>
    );
};

export default BackgroundCard;
