import React from 'react';
import { graphql } from 'gatsby';
import LandingLayout from '../layouts/Landing';
import Products from '../routes/Products';

interface ProductsEnPageProps {
    data: {
        site: {
            siteMetadata: {
                name: string;
                tagline: string;
            };
        };
    };
}

const productsEn = ({
    data: {
        location,
        site: {
            siteMetadata: { name },
        },
    },
}: ProductsEnPageProps) => {
    return (
        <LandingLayout lang="en" location={location}>
            <Products lang="en" />
        </LandingLayout>
    );
};

export const productsEnPageQuery = graphql`
    query productsEnPageQuery {
        site {
            siteMetadata {
                name
                tagline
            }
        }
    }
`;

export default productsEn;
