import styled from 'styled-components';

export const Section = styled.div`
    padding-top: 192px;
    padding-bottom: 128px;
    text-align: center;
    background: ${({ theme }) => theme.palette.primary.main};

    a {
        text-decoration: none;
    }

    h2 {
        color: ${({ theme }) => theme.palette.secondary.main};
    }
`;

export const SectionHeader = styled.div`
    margin-bottom: 80px;
`;
