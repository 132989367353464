export default {
    en: {
        title: 'Our products',
        subtitle:
            'There are two main product programmes dedicated to healthy nutrition, both composed of an ample range of specialties that allow the retailer to offer clients a proposal of added value.',
        taste: 'Taste & Nutrition',
        health: 'Health & Wellbeing',
    },
    it: {
        title: 'I nostri prodotti',
        subtitle:
            'Due sono i programmi principali di prodotti dedicati alla nutrizione salutare, entrambi composti da un’ampia gamma di specialità che consentono alla rivendita di offrire ai propri clienti una proposta di valore aggiunto.',
        taste: 'Gusto & Alimenti',
        health: 'Benessere & Salute',
    },
};
